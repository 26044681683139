import React from 'react'
import { Link } from 'gatsby'

import ReactWOW from 'react-wow'
import screen from '../../assets/images/home/main-screen.png'
import shape9 from '../../assets/images/common/graphic-blob-corner1.png'
import shape10 from '../../assets/images/common/graphic-blob-corner2.png'

const AgilityPromo = () => {
    return (
        <div className="project-start-area bg-color ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={screen} alt="screen" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2>ekko Agility</h2>
                            <p>Confluence and Jira templates, workflows to support information security programs, ISO27001, ISO27701, NIST CSF, Cyber Essentials and more...</p>
                            <Link to="/contact" className="default-btn">
                              
                                Request a Demo 
                        
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vector-shape9">
                <img src={shape9} alt="about" />
            </div>
            <div className="vector-shape10">
                <img src={shape10} alt="about" />
            </div>
        </div>
    )
}

export default AgilityPromo
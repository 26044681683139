import React from 'react'
// import {Link} from 'gatsby'
import shape1 from '../../assets/images/services/service-g1.png'
import shape2 from '../../assets/images/services/service-g2.png'
import shape3 from '../../assets/images/services/service-g4.png'
// import ReactWOW from 'react-wow'
import Bubbles2 from './Bubbles2';

const PageBannerServices = ({pageTitle, homePageUrl, screenShot2, homePageText, screenShot, animation, activePageText}) => {
    return (
        <div className="services-page-title-area services-hero">
        <div className="wave-border" /> 
         <Bubbles2 /> 
          <div className="graphics"/> 
          
            <div id="jellyfish" /> 
            <div className="container">
                <div className="row align-items-center">
                    <div className="page-title-content col-lg-6 col-md-12">
                        <h2 style={{color: 'white'}}>{pageTitle}</h2>
                        <ul>
                            <li>
                                <h4 style={{color: 'white', fontWeight: '500'}}>
                                    {homePageText}
                                </h4>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        {/* <ReactWOW>
                            <div style={{position: 'absolute', zIndex: 20, }} className="banner-wrapper-image spin">
                                <img src={screenShot} alt="banner" />
                            </div>
                        </ReactWOW>
                         <ReactWOW>
                            <div  style={{position: 'absolute', zIndex: 30, }}  className="banner-wrapper-image spin">
                                <img src={screenShot} alt="banner" />
                            </div>
                        </ReactWOW> */}
                        <div className="banner-wrapper-image" style={{position:'relative', zIndex: 20000}}>
                            <img src={screenShot} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
           
             <div className="shape-img2" style={{maxWidth: 400}}>
                <img src={shape1} alt="banner" />
            </div>
            <div className="shape-img3" style={{maxWidth: 300}}>
                <img src={shape2} alt="banner" />
            </div>
             <div className="shape-img4" style={{maxWidth: 100}}>
                <img src={shape3} alt="banner" />
            </div>
            <div className="shape-img5" style={{maxWidth: 40}}>
                <img src={shape3} alt="banner" />
            </div>
            <div className="shape-img6" style={{maxWidth: 80}}>
                <img src={shape3} alt="banner" />
            </div>
            <div className="shape-img7" style={{maxWidth: 50}}>
                <img src={shape3} alt="banner" />
            </div>   
  
        </div>
    )
}

export default PageBannerServices;